import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-new-sinistre',
  templateUrl: './new-sinistre.component.html',
  styleUrls: ['./new-sinistre.component.css']
})
export class NewSinistreComponent implements OnInit {

  title = 'New Sinistre';
  
  constructor(private titleService: Title) { 
    this.titleService.setTitle(environment.APP_TITLE + ' | '+ this.title);
  }

  ngOnInit(): void {
  }

}
