<!-- .hero -->
<section class="hero">
    <div class="hero-body">
        <div class="container">
            <div class="columns is-fullwidth is-full is-vcentered header-nav">
                <div class="column is-1">
                    <figure class="image is-96x96">
                        <a routerLinkActive="is-primary" routerLink={{quickAccessLink}}><img src="assets/img/icon-five-insurance-square.svg"></a>
                    </figure>
                </div>
                <div class="column is-11">
                    <h1 class="title mb-1">
                        FIVE INSURANCE
                    </h1>
                    <h2 class="pt-0">Compte client</h2>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- .hero -->
 