import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

import { Router } from '@angular/router';
import { CommonFunctionService } from '../common-function.service';    

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  title = "Dashboard";
  isLoading = true;

  constructor(public router: Router,private http: HttpClient, private titleService: Title,
    private CommonFunctionService: CommonFunctionService   
    ) {
    localStorage.removeItem('packLists');
    localStorage.removeItem('singlePackIndex');
    localStorage.removeItem('isShowInnerMenu');
    this.titleService.setTitle(environment.APP_TITLE + ' | '+ this.title);
   }

  loggedInName = "";
  mntAmount = 0;
  userMail = "";
  packsLists = [{
    PACK:'',
    MOTIF:'',
    LIBPRODUIT:'',
    MOUVEMENT:'',
    MARQUEVEH:'',
    IMMATVEH:'',
    ADRHAB:'',
    TYPEVEH:'',
    CATEGORIEVEH:'',
    NOM:''
  }];

  ngOnInit(): void {

    this.CommonFunctionService.isUserLoggedIn();
    
    /* Details of the connected user  */
    var httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
    }
    this.http.get<any>(environment.API_BASE_URL+'/personne',httpOptions).subscribe(
      (response) => {
        var responseJson = response;     
        if(responseJson.prenom!=null){
          this.loggedInName = responseJson.prenom+' '+responseJson.nom;
          this.userMail = responseJson.mail;
          localStorage.setItem('userProfile',JSON.stringify(responseJson));
        }
      },
      (error) => { 
        /* Auto Logout if token is expired and returning 401 error */
        if(error.status==401){ this.CommonFunctionService.userLogout() } 
      }
    )


    /* Amount to pay of the connected user  */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_GETSOLDECLIENT',httpOptions).subscribe(
      (response) => {
        var responseJson = response;
        if(responseJson.statusCode==0){
          this.mntAmount = parseFloat(response.beans[0].MNT);
          localStorage.setItem('mntAmount',String(this.mntAmount));
        }
      },
      (error) => { console.log(error); }
    )


    /* Get Packs Lists */
    /* Re use above httpsOptions variable */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_POL_DEV',httpOptions).subscribe(
      (response) => {
        var responseJson = response;
        if(responseJson.statusCode==0){
          this.isLoading = false;
          this.packsLists = responseJson.beans;
          localStorage.setItem('packLists',JSON.stringify(responseJson.beans));
        }
      },
      (error) => { console.log(error); }
    )

    /* Broker Details */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_COMINT_TIDPER',httpOptions).subscribe(
      (response) => {
        localStorage.removeItem('brokerLogo');
        localStorage.removeItem('brokerLogoName');
        var responseJson = response;
        // console.log(response);
        if(responseJson.statusCode==0){
          // this.isLoading = false;
          localStorage.setItem('brokerDetails',JSON.stringify(responseJson.beans[0]));

          /* Get Logo */
          this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_LOGO_COURTIER/?TIDPER='+responseJson.beans[0].ID_PER,httpOptions).subscribe(
            (response) => {
              // console.log(response);
              var responseJson = response;
              if(responseJson.statusCode==0 && responseJson.beans.length > 0){
                //319584
                this.http.get<any>(environment.API_BASE_URL+'/document/v2/'+responseJson.beans[0].ID_ADOC,httpOptions).subscribe(
                  (response) => {
                    localStorage.setItem('brokerLogo',String(response.datas));
                    localStorage.setItem('brokerLogoName',String(response.name));
                  }
                )
              }
          });
        }
      },
      (error) => {  
        /* Auto Logout if token is expired and returning 401 error */
        if(error.status==401){ this.CommonFunctionService.userLogout() }     
      }
    )


    /* GET Special conditions of the connected user */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_CP_TIDPER',httpOptions).subscribe(
      (response) => {
        var responseJson = response;
        if(responseJson.statusCode==0){
          // this.isLoading = false;
          localStorage.setItem('specialConditionsOfConnectedUser',JSON.stringify(responseJson.beans));
        }
      }
    )
    
    /* CV Of Connected USER */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_CV_TIDPER',httpOptions).subscribe(
      (response) => {
        var responseJson = response;     
        // this.documents = responseJson.beans; 
        localStorage.setItem('cvOfConnectedUser',JSON.stringify(responseJson.beans));
      }
    )
      
    /* Notice of deadline of the connected user */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_AE_TIDPER',httpOptions).subscribe(
      (response) => {
        var responseJson = response;
        if(responseJson.statusCode==0){
          // this.isLoading = false;
          localStorage.setItem('noticeDeadlineOfConnectedUser',JSON.stringify(responseJson.beans ));
        }
      }
    )

  }


  go_single_pack(indexValue:any){
    var index = parseInt(indexValue);
    if(indexValue >= 0){
      localStorage.setItem('singlePackIndex',indexValue);
      localStorage.setItem('isShowInnerMenu','true');
      this.router.navigate(['quick-access/'+indexValue]);
    }
  }

  updatePackName(packNamme:any){
    if(packNamme!=null){
      return packNamme.replace(/^0+/, '');
    }else{
      return packNamme;
    }
  }

}
