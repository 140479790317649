<app-header></app-header>

<section class="section pt-2 table_sm_ui sinistre_section">
    <div class="container">

        <app-top-menu></app-top-menu>


        <div class="container">
            <h3 class="title is-size-3">Déclarer un sinistre</h3>
            <h4 class="subtitle is-size-4">2329 Maxime Barbiaux</h4>
        </div>
        <div class="has-text-centered">
            <a [routerLink]="['/new-sinistres']" class="button is-link">Déclarer un sinistre</a>
        </div>
        <div class="container">
            <table class="table center is-fullwidth is-striped mt-6">
                <thead>
                    <tr>
                        <th>Sinistres en cours</th>
                        <th>Risque</th>
                        <th>Statut</th>
                        <th>Date de la déclaration</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>RC Locataire Sérénité</td>
                        <td>Rue Abbéchamps, 73</td>
                        <td>En cours</td>
                        <td>12/02/2019</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="container">
            <table class="table center is-fullwidth is-striped mt-6">
                <thead>
                    <tr>
                        <th>Sinistres clôturés</th>
                        <th>Risque</th>
                        <th>Statut</th>
                        <th>Date de la déclaration</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>RC Locataire Sérénité</td>
                        <td>Rue Abbéchamps, 73</td>
                        <td>Passé</td>
                        <td>12/02/2019</td>
                    </tr>
                    <tr>
                        <td>Omnium Privilège</td>
                        <td>Audi 1-AAA-489</td>
                        <td>Passé</td>
                        <td>12/02/2019</td>
                    </tr>
                    <tr>
                        <td>RC Locataire Sérénité</td>
                        <td>Rue Abbéchamps, 73</td>
                        <td>Passé</td>
                        <td>12/02/2019</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>

<app-footer></app-footer>