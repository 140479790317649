import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonFunctionService } from '../common-function.service';

import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  title = "Mes documents";
  isLoading = true;
  currentDoc = "0";
  currentFilter = 3;
  docLoader = false;
  documents = [{ 'CONFID': '', 'NOM_ADOC': '', 'DAT_ADOC': '', 'ID_ADOC': '' }];
  filterDocuments = this.documents;
  currentPack = {
    PACK: '',
    NOM: '',
    index: 0
  };

  // currentFilter = 3;
  // {'key':1,'value':'Tout afficher'},
  filterButtons = [{ 'key': 3, 'value': 'Documents comptables' }, { 'key': 4, 'value': 'Conditions particulères' }, { 'key': 2, 'value': "Certificats d'assurance" }];

  constructor(private titleService: Title, private http: HttpClient, private CommonFunctionService: CommonFunctionService, private route: ActivatedRoute) {
    this.titleService.setTitle(environment.APP_TITLE + ' | ' + this.title);
  }

  ngOnInit(): void {
    this.CommonFunctionService.isUserLoggedIn();

    /* Assign current pack from construct variable */
    this.currentPack.PACK = this.CommonFunctionService.getCurrentPackDetails('PACK') || '';
    this.currentPack.NOM = this.CommonFunctionService.getCurrentPackDetails('NOM') || '';
    this.currentPack.index = Number(localStorage.getItem('singlePackIndex'));

    /* Details of the connected user  */
    var httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('loggedInToken') })
    }

    /* Filter according to index param found in URL */
    this.route.paramMap.subscribe((params: ParamMap) => {
        this.currentFilter = Number(params.get('filterId') ?? this.currentFilter)
        this.doFilterData(this.currentFilter)
    })

  }


  /* Get Document from doc id */
  getAdDoc(adDocId: any) {
    this.currentDoc = adDocId;
    this.docLoader = true;

    var httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('loggedInToken') })
    }
    this.http.get<any>(environment.API_BASE_URL + '/document/v2/' + this.currentDoc, httpOptions).subscribe(
      (response) => {
        this.docLoader = false;
        this.downloadPDF(response.name, response.datas);

      }
    )
  }

  /* Donwload PDF */
  downloadPDF(name: any, data: any) {

    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  /* Filter by tag */
  doFilterData(filterKey: any) {
    this.currentFilter = filterKey;
    var tempFilter = Array();
    var httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('loggedInToken') })
    }
    this.isLoading = true;

    try {
      if (filterKey == 2) {
        // tempFilter.push(items);
        this.http.get<any>(environment.API_BASE_URL + '/boBy/WS_FIVE_CV_TIDPER', httpOptions).subscribe(
          (response) => {
            var responseJson = response;
            this.isLoading = false;
            this.documents = responseJson.beans;
            this.filterDocuments = this.documents;
          }
        )
      }
      if (filterKey == 3) {
        this.http.get<any>(environment.API_BASE_URL + '/boBy/WS_FIVE_AE_TIDPER', httpOptions).subscribe(
          (response) => {
            var responseJson = response;
            this.isLoading = false;
            this.documents = responseJson.beans;
            this.filterDocuments = this.documents;
          }
        )
      }
      if (filterKey == 4) {
        this.http.get<any>(environment.API_BASE_URL + '/boBy/WS_FIVE_CP_TIDPER', httpOptions).subscribe(
          (response) => {
            var responseJson = response;
            this.isLoading = false;
            this.documents = responseJson.beans;
            this.filterDocuments = this.documents;
          }
        )
      }
    } catch(error: any) {
      /* Auto Logout if token is expired and returning 401 error */
      if (error.status == 401) { this.CommonFunctionService.userLogout() }
    }

    this.filterDocuments = tempFilter || [];
  }

  getDownloadMesAssurancesId() {
    var spCondsOfConUsr = JSON.parse(localStorage.getItem('specialConditionsOfConnectedUser') || '[{}]');
    var returnId = '0';
    if (spCondsOfConUsr.length > 0) {
      spCondsOfConUsr.sort((a: any, b: any) => {
        return <any>new Date(b.DAT_ADOC) - <any>new Date(a.DAT_ADOC);
      });

      spCondsOfConUsr.forEach((singleSpCond: any) => {
        if (singleSpCond.NOM_ADOC.startsWith('AVT_') && returnId == '0') {
          returnId = singleSpCond.ID_ADOC;
        }
      });
      // console.log(returnId);
    }
    return returnId;
  }

  updatePackName(packNamme: any) {
    if (packNamme != null) {
      return packNamme.replace(/^0+/, '');
    } else {
      return packNamme;
    }
  }

}
