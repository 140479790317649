import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
import { CommonFunctionService } from '../common-function.service';  

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.css']
})
export class QuickAccessComponent implements OnInit {

  title = 'Accès rapide';
  mntAmount = parseFloat(localStorage.getItem('mntAmount') || '');
  isLoading = true;
  isModifyPasswordFormShow = 'false';

  currentPack = {
    PACK:'',
    NOM:'',
    index:0
  };

  userDatailsLoader = true;
  userDatails = {
    prenom:'',
    nom:'',
    dtNaissance:'',
    mail:'',
    mobile:'',
    adresse1:'',
    adresse2:'',
    ville:'',
    cp:'',
    complementCp:''
  };

  spCondUsrLoader = true;
  spCondUsr = JSON.parse(localStorage.getItem('specialConditionsOfConnectedUser') || '[{}]');

  ntDeadCondUsr = JSON.parse(localStorage.getItem('noticeDeadlineOfConnectedUser') || '[{}]');
  currentDoc = 0;
  docLoader = false;
  
  packsLists = JSON.parse(localStorage.getItem('packLists') || '{}');
  cvOfConnectedUser = JSON.parse(localStorage.getItem('cvOfConnectedUser') || '[{}]');

  brokerDetailsLoader = true;
  brokerDetails = {
    NOM_PER:'',
    ADRESSE1_ADR:'',
    TELPRINC_PER:'',
    EMAIL_PER:'',
    Logo :''
  };

  isRConnectedLoader = true;
  rConnectedUser = [{
    'NOM_PER':'',
    'PRENOM_PER':'',
    'DT_NAISS':''
  }];
  
  constructor(private titleService: Title,
    public router: Router,
    private CommonFunctionService: CommonFunctionService ,
    private http: HttpClient,
    private route: ActivatedRoute
    ) { 
      
      /* Read data from url */
      if(this.route.snapshot.params['packId']!=null && this.route.snapshot.params['packId']!=undefined){
        localStorage.setItem('singlePackIndex',this.route.snapshot.params['packId']);
        this.route.queryParams.subscribe(params => {
            var accesstoken = params['igaToken'];
            var refreshToken = params['accessToken'];

            if(accesstoken==undefined){
              this.fatchAllDatas();
            }else{
              localStorage.setItem('loggedInToken',accesstoken);
              localStorage.setItem('ssoAccessToken',refreshToken);
  
              /* Refresh Access token */
              var httpOptions = {
                headers: new HttpHeaders({'Content-Type': 'application/json','X-Authorization':'Bearer '+refreshToken})
              }
              this.http.get<any>(environment.API_REFRESH_A_TOKEN+'/iga_token/'+this.route.snapshot.params['packId'],httpOptions).subscribe(
                (response) => {
                  var responseJson = response;     
                  console.log(responseJson[0].token);
                  localStorage.setItem('loggedInToken',responseJson[0].token);
                  this.fatchAllDatas();
                },
                (error) => { 
                  console.log(error);
                }
              )
            }
        });
        
      }


      this.titleService.setTitle(environment.APP_TITLE + ' | '+ this.title);

      // /* Get Current Pack Details */
      this.currentPack.PACK = this.CommonFunctionService.getCurrentPackDetails('PACK') || '';
      this.currentPack.NOM = this.CommonFunctionService.getCurrentPackDetails('NOM')  || '';
      // this.currentPack.index = Number(localStorage.getItem('singlePackIndex'));
      
  }


  fatchAllDatas(){
    this.getUserProfile();
    this.getDownloadMesAssurancesId();
    this.getBrokerLogo();
    this.getConnectedRelated();
    this.getPackLists();
  }

  getPackLists(){
    var httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
    }
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_POL_DEV',httpOptions).subscribe(
      (response) => {
        var responseJson = response;
        if(responseJson.statusCode==0){
          this.isLoading = false;
          this.packsLists = responseJson.beans;
          localStorage.setItem('packLists',JSON.stringify(responseJson.beans));
        }
      },
      (error) => { console.log(error); }
    )
  }

  getUserProfile(){
    var httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
    }
    this.http.get<any>(environment.API_BASE_URL+'/personne',httpOptions).subscribe(
      (response) => {
        var responseJson = response;     
        if(responseJson.prenom!=null){
          localStorage.setItem('userProfile',JSON.stringify(responseJson));

          this.userDatailsLoader = false;
          this.userDatails = { 
            'nom':this.CommonFunctionService.getUserProfileDetails('nom'),
            'prenom':this.CommonFunctionService.getUserProfileDetails('prenom'),
            'dtNaissance':this.CommonFunctionService.getUserProfileDetails('dtNaissance'),
            'mail':this.CommonFunctionService.getUserProfileDetails('mail'),
            'mobile':this.CommonFunctionService.getUserProfileDetails('mobile'),
            'adresse1':this.CommonFunctionService.getUserProfileDetails('adresses'),
            'adresse2':this.CommonFunctionService.getUserProfileDetails('adresse2'),
            'ville':this.CommonFunctionService.getUserProfileDetails('ville'),
            'cp':this.CommonFunctionService.getUserProfileDetails('cp'),
            'complementCp':this.CommonFunctionService.getUserProfileDetails('complementCp')
          }
        }
      }
    )
  }
  
  getBrokerLogo(){
    /* Broker Logo */
    var httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
    }
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_COMINT_TIDPER',httpOptions).subscribe(
      (response) => {
        localStorage.removeItem('brokerLogo');
        localStorage.removeItem('brokerLogoName');
        var responseJson = response;
        // console.log(response);
        if(responseJson.statusCode==0){
          // this.isLoading = false;
          localStorage.setItem('brokerDetails',JSON.stringify(responseJson.beans[0]));

          var brokerDetailsFromStorage = JSON.parse(localStorage.getItem('brokerDetails') || '{}');

          this.brokerDetails.NOM_PER=brokerDetailsFromStorage.NOM_PER || "";
          this.brokerDetails.ADRESSE1_ADR=brokerDetailsFromStorage.ADRESSE1_ADR || "";
          this.brokerDetails.TELPRINC_PER=brokerDetailsFromStorage.TELPRINC_PER || "";
          this.brokerDetails.EMAIL_PER=brokerDetailsFromStorage.EMAIL_PER || "";
          this.brokerDetailsLoader = false;
          /* Get Logo */
          this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_LOGO_COURTIER/?TIDPER='+responseJson.beans[0].ID_PER,httpOptions).subscribe(
            (response) => {
              // console.log(response);
              var responseJson = response;
              if(responseJson.statusCode==0 && responseJson.beans.length > 0){
                //319584
                this.http.get<any>(environment.API_BASE_URL+'/document/v2/'+responseJson.beans[0].ID_ADOC,httpOptions).subscribe(
                  (response) => {
                    localStorage.setItem('brokerLogo',String(response.datas));
                    localStorage.setItem('brokerLogoName',String(response.name));

                    var logo = localStorage.getItem('brokerLogo');
                    var logoName = localStorage.getItem('brokerLogoName') || '';
                    var ext = logoName.split('.').pop();
                    var realLogo = 'data:image/'+ext+';base64,'+localStorage.getItem('brokerLogo');
                    this.brokerDetails.Logo = realLogo;

                  }
                )
              }
          });
        }
      }
    )
  }

  showPasswordForm(){
    // this.isModifyPasswordFormShow = 'true';
    window.location.href = 'https://five.be/mot-de-passe-oublie';
  }

  getDownloadMesAssurancesId(){
    var httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
    }
    /* GET Special conditions of the connected user */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_CP_TIDPER',httpOptions).subscribe(
      (response) => {
        var responseJson = response;
        if(responseJson.statusCode==0){
          // this.isLoading = false;
          localStorage.setItem('specialConditionsOfConnectedUser',JSON.stringify(responseJson.beans));
          var spCondsOfConUsr = JSON.parse(localStorage.getItem('specialConditionsOfConnectedUser') || '[{}]');

          var returnId = Array();
          if(spCondsOfConUsr.length > 0){
            spCondsOfConUsr.sort((a:any, b:any) => {
              return <any>new Date(b.DAT_ADOC) - <any>new Date(a.DAT_ADOC);
            });

            spCondsOfConUsr.forEach((singleSpCond : any)=> {
              if(singleSpCond.NOM_ADOC.startsWith('AVT_') && returnId.length == 0){
                returnId.push(singleSpCond);
              }
            });
            // console.log(returnId);
          }
          this.spCondUsrLoader = false;
          this.spCondUsr = returnId;
        }
      }
    )
    
    /* CV Of Connected USER */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_CV_TIDPER',httpOptions).subscribe(
      (response) => {
        var responseJson = response;     
        // this.documents = responseJson.beans; 
        localStorage.setItem('cvOfConnectedUser',JSON.stringify(responseJson.beans));
      }
    )
      
    /* Notice of deadline of the connected user */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_AE_TIDPER',httpOptions).subscribe(
      (response) => {
        var responseJson = response;
        if(responseJson.statusCode==0){
          // this.isLoading = false;
          localStorage.setItem('noticeDeadlineOfConnectedUser',JSON.stringify(responseJson.beans ));
          this.ntDeadCondUsr = responseJson.beans;
        }
      }
    )
  }
 

  ngOnInit(): void {
    this.CommonFunctionService.isUserLoggedIn();
  }

  getConnectedRelated(){
    /* Broker name of the connected user for Mon courtier area */
    var httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
    }

    /* GET Connected user’s related */
    this.http.get<any>(environment.API_BASE_URL+'/boBy/WS_FIVE_CONNEXES_TIDPER',httpOptions).subscribe(
      (response) => {
        var responseJson = response;
        if(responseJson.statusCode==0){
          this.isRConnectedLoader = false;
          this.rConnectedUser = responseJson.beans;
          localStorage.setItem('connectedUserRelated',JSON.stringify(responseJson.beans));
        }
      },
      (error) => { console.log(error); }
    )
  }



  go_single_pack(indexValue:any){
    var index = parseInt(indexValue);
    if(indexValue >= 0){
      localStorage.setItem('singlePackIndex',indexValue);
      localStorage.setItem('isShowInnerMenu','true');
      window.location.reload();
    }
  }


  /* Get Document from doc id */
  getAdDoc(adDocId:any){
    this.currentDoc = adDocId;
    this.docLoader = true;

    var httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
    }
    this.http.get<any>(environment.API_BASE_URL+'/document/v2/'+this.currentDoc,httpOptions).subscribe(
      (response) => {
        this.docLoader = false;
        this.downloadPDF(response.name,response.datas);
        
      }
    )
  }

  /* Donwload PDF */
  downloadPDF(name:any,data:any) {

    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  updatePackName(packNamme:any){
    if(packNamme!=null){
      return packNamme.replace(/^0+/, '');
    }else{
      return packNamme;
    }
  }

  getDownloadDocButtonCode(IDPOL:any){
    var downloadBtn = 0;
    var cvItems = this.cvOfConnectedUser;
    cvItems.forEach((items:any) => {
      if(items.IDTAB_ADOC==IDPOL){
        downloadBtn = items.ID_ADOC;
      }
    });

    return downloadBtn;
  }

}
