import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { AssurancesComponent } from './assurances/assurances.component';
import { SinistresComponent } from './sinistres/sinistres.component';
import { DocumentsComponent } from './documents/documents.component';
import { QuickAccessComponent } from './quick-access/quick-access.component';
import { ContactComponent } from './contact/contact.component';
import { NewSinistreComponent } from './new-sinistre/new-sinistre.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    TopMenuComponent,
    AssurancesComponent,
    SinistresComponent,
    DocumentsComponent,
    QuickAccessComponent,
    ContactComponent,
    NewSinistreComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
