<app-header></app-header>

<section class="section pt-2 table_sm_ui document_section">
    <div class="container">

        <app-top-menu></app-top-menu>


        <div class="container">
            <h3 class="title is-size-3">Mes documents</h3>
            <h4 class="subtitle is-size-4">{{updatePackName(currentPack.PACK)}} {{currentPack.NOM}}</h4>
        </div>
        <section class="">
            <div class="container mb-4">
                <span *ngFor="let singleButton of filterButtons; let i = index">
                    <button *ngIf="singleButton.key==currentFilter" (click)="doFilterData(singleButton.key)" class="button is-info is-rounded">{{singleButton.value}}</button>
                    <button *ngIf="singleButton.key!=currentFilter" (click)="doFilterData(singleButton.key)" class="button is-rounded">{{singleButton.value}}</button>
                </span>
            </div>
            <div class="container">
                <table class="table center is-fullwidth is-striped">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Nom du document</th>
                            <th>Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tr *ngIf="isLoading">
                        <td colspan="4" style="text-align: center;">
                            <div class="apiLoader">
                                <i class="fa fa-refresh fa-spin"></i>
                            </div>
                        </td>
                    </tr>
                    <tbody *ngIf="isLoading==false">
                        
                        <tr *ngFor="let singleDoc of filterDocuments; let i = index">
                            <td *ngIf="singleDoc.CONFID=='QUI'">Quittance</td>
                            <td *ngIf="singleDoc.CONFID=='CP'">Conditions particulières</td>
                            <td *ngIf="singleDoc.CONFID=='CV'">Certificats d'assurance</td>
                            <td>{{singleDoc.NOM_ADOC}}</td>
                            <td>{{singleDoc.DAT_ADOC}}</td>
                            <td class="has-text-centered">
                                <div class="button is-small" (click)="getAdDoc(singleDoc.ID_ADOC)">
                                    <span *ngIf="docLoader==false || currentDoc!=singleDoc.ID_ADOC">Voir</span>
                                    <span *ngIf="currentDoc==singleDoc.ID_ADOC && docLoader"><i class="fa fa-refresh fa-spin"></i></span>
                                </div>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
                <div class="has-text-centered">
                    <button class="button is-link" *ngIf="getDownloadMesAssurancesId()!='0'" (click)="getAdDoc(getDownloadMesAssurancesId())">Voir le contrat de mes assurances 
                        <span *ngIf="currentDoc==getDownloadMesAssurancesId() && docLoader"><i class="fa fa-refresh fa-spin"></i></span>
                    </button>
                </div>
            </div>
        </section>
    </div>
</section>


<app-footer></app-footer>