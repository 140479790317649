<app-header></app-header> 
<!-- .hero -->
<section class="section pt-2 table_sm_ui quick-access_section">
    <div class="container">

        <app-top-menu></app-top-menu>
        
        <div class="container">
            <h2 class="title is-size-2 mb-0">Pack n°{{updatePackName(currentPack.PACK)}}</h2>
            <h3 class="title is-size-3">{{currentPack.NOM}}</h3>
            <div class="columns">
                <div class="column">
                    <div class="tab-noti">
                        <div class="noti-payer">
                            <div *ngIf="mntAmount > 0" class="notification has-background-light">
                                <div class="columns flexNotificationCol">
                                    <div class="column">
                                        <div class="has-text-left">
                                            <!-- <p class="tag is-large">Solde en votre faveur</p> -->
                                            <p class="tag is-large">Montant à payer</p>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <div class="has-text-right">
                                            <!-- Valeur en positif ou négatif du solde client -->
                                            <!-- Si négatif : "is-danger" ; si positif : "is-primary" -->
                                            <!-- <span class="tag is-primary is-large">-29,35&nbsp;€</span> -->
                                            <span class="tag is-danger is-large">{{mntAmount}}&nbsp;€</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="notification documents has-background-white">
                                <div class="api-inner-loader" *ngIf="spCondUsrLoader==true" >
                                    <i class="fa fa-refresh fa-spin"></i>
                                </div>
                                <ng-container *ngIf="spCondUsrLoader==false">
                                    <div class="columns" *ngFor="let singleSpCond of spCondUsr; let i = index" >
                                        <ng-container *ngIf="i < 1">
                                            <div class="column is-10">
                                                <h4 class="title is-size-6">
                                                    Situation actuelle de votre pack
                                                </h4>
                                                <span>Afficher le contrat le plus récent</span>
                                            </div>
                                            <div class="column has-text-right is-2">
                                                <button (click)="getAdDoc(singleSpCond.ID_ADOC)" class="button is-link is-small">
                                                    <span *ngIf="docLoader==false || currentDoc!=singleSpCond.ID_ADOC">Voir</span>
                                                    <span *ngIf="currentDoc==singleSpCond.ID_ADOC && docLoader"><i class="fa fa-refresh fa-spin"></i></span>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="columns" *ngFor="let singleNtDead of ntDeadCondUsr; let i = index">
                                        <ng-container *ngIf="i < 1">
                                            <div class="column is-10">
                                                <h4 class="title is-size-6">
                                                    Votre avis d’échéance
                                                </h4>
                                                <span>Avis d’échéance au {{singleNtDead.DAT_ADOC}}</span>
                                            </div>
                                            <div class="column has-text-right is-2">
                                                <button (click)="getAdDoc(singleNtDead.ID_ADOC)" class="button is-link is-small">
                                                    <span *ngIf="docLoader==false || currentDoc!=singleNtDead.ID_ADOC">Voir</span>
                                                    <span *ngIf="currentDoc==singleNtDead.ID_ADOC && docLoader"><i class="fa fa-refresh fa-spin"></i></span>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="content has-text-centered">
                                        <a [routerLink]="['/documents']" class="button is-text is-spaced">Voir tous mes documents</a>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="noti-assurances">
                            <div class="card cartes-vertes">
                                <div class="card-image">
                                    <p class="card-header-title card-title is-overlay">
                                        Certificats d'assurances
                                    </p>
                                    <figure class="image is-360x37 is-fullwidth">
                                        <img src="assets/img/cars.png">
                                    </figure>
                                </div>
                                <div class="card-content" *ngIf="packsLists.length > 0">
                                    <table class="table is-fullwidth is-full table-desi">
                                        <ng-container *ngFor="let singlePack of packsLists; let i = index">
                                            <ng-container> <!-- *ngIf="singlePack.PACK==currentPack.PACK" -->
                                                <ng-container *ngIf="(singlePack.CDPOS=='EC' || singlePack.CDPOS=='SUS')">
                                                    <tr *ngIf="singlePack.MARQUEVEH!=null || singlePack.IMMATVEH!=null">
                                                        <td *ngIf="singlePack.MARQUEVEH!=null || singlePack.IMMATVEH!=null">
                                                            <span class="icon">
                                                            
                                                            <i *ngIf="singlePack.CATEGORIEVEH=='Automobile'" class="fas fa-car"></i>
                                                            <i *ngIf="singlePack.CATEGORIEVEH!='Automobile'" class="fas fa-motorcycle"></i>
            
                                                            </span> {{singlePack.MARQUEVEH}} {{singlePack.IMMATVEH}}</td>
                                                        <td *ngIf="singlePack.MARQUEVEH!=null || singlePack.IMMATVEH!=null" style="padding:0;">
                                                            <div class="column has-text-right is-2">
                                                                <!-- <button (click)="go_single_pack(i)" class="button is-link is-small">Voir</button> -->
                                                                <!-- {{singlePack.IDPOL | getDownloadDocButtonCode}} -->
                                                                <button *ngIf="getDownloadDocButtonCode(singlePack.IDPOL)!=0" (click)="getAdDoc(getDownloadDocButtonCode(singlePack.IDPOL))" class="button is-link is-small">
                                                                    <span *ngIf="docLoader==false || currentDoc!=getDownloadDocButtonCode(singlePack.IDPOL)">Voir</span>
                                                                    <span *ngIf="currentDoc==getDownloadDocButtonCode(singlePack.IDPOL) && docLoader"><i class="fa fa-refresh fa-spin"></i></span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </table>
                                </div>
                            </div>
                        <div class="content has-text-centered mt-4">
                            <a [routerLink]="['/documents/2']" class="button is-text">Voir tous mes certificats d'assurance</a>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="column">
                    <!-- mb-4 means Margin-Bottom- and 4 equals to 1 rem -->
                    <!-- for more info: -->
                    <!-- https://bulma.io/documentation/helpers/spacing-helpers/ -->
                    <div class="card courtier mb-4 tab-courtier">
                        <div class="card-header">
                            
                            <div class="container">
                                <div class="columns is-full">
                                    <div class="column is-three-quarters">
                                        <h4 class="card-header-title is-size-4">Mon courtier</h4> 
                                        <div class="api-inner-loader" *ngIf="brokerDetailsLoader==true" >
                                            <i class="fa fa-refresh fa-spin"></i>
                                        </div>
                                        <ng-container *ngIf="brokerDetailsLoader==false">
                                            <div class="card-content pt-2 pb-0">
                                                <p><strong>{{brokerDetails.NOM_PER}}</strong></p>
                                                <p>{{brokerDetails.ADRESSE1_ADR}}</p>
                                                <p>Téléphone : <a href="tel:{{brokerDetails.TELPRINC_PER}}">{{brokerDetails.TELPRINC_PER}}</a></p>
                                                <p>Email : <a href="mailto:{{brokerDetails.EMAIL_PER}}">{{brokerDetails.EMAIL_PER}}</a></p>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="column is-one-quarter">
                                        <figure class="image is-64x64 mt-4 has-text-right">
                                            <img class="is-rounded" *ngIf="brokerDetails.Logo==''" src="https://bulma.io/images/placeholders/64x64.png">
                                            <img class="is-rounded" *ngIf="brokerDetails.Logo!=''" src={{brokerDetails.Logo}}>
                                        </figure>
                                    </div>
                                </div>
                                <div class="column is-full pt-0 text-sm-center">
                                    <h5 class="title is-size-5">
                                        Contact
                                    </h5>
                                    <h6 class="subtitle is-6">Pour modifier des données, signaler un déménagement, ou toute autre demande&nbsp;:</h6>
                                    <div class="content has-text-centered">
                                        <a [routerLink]="['/contact']" class="button is-text">Contacter mon courtier</a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="container">
                        <div class="notification has-background-white tab-pack">
                            <h4 class="title is-size-4">
                                Assurés du Pack
                            </h4>
                            <table class="table center is-fullwidth mon_courtiner_table">
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Prénom</th>
                                        <th>Date de naissance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{userDatails.nom}}</td> 
                                        <td>{{userDatails.prenom}}</td>
                                        <td>{{userDatails.dtNaissance}}</td>
                                    </tr>
                                    <tr *ngFor="let singleConnectedUser of rConnectedUser; let i = index">
                                        <td>{{singleConnectedUser.NOM_PER}}</td> 
                                        <td>{{singleConnectedUser.PRENOM_PER}}</td>
                                        <td>{{singleConnectedUser.DT_NAISS}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="column">
                    <div class="notification has-background-white mb-0 tab-mes">
                        <h4 class="title is-size-4">
                            Mes coordonnées
                        </h4>
                        <div class="api-inner-loader" *ngIf="userDatailsLoader==true" >
                            <i class="fa fa-refresh fa-spin"></i>
                        </div>
                        <ng-container *ngIf="userDatailsLoader==false">
                            <div class="columns mes-colums">
                                <div class="column">
                                    <h5 class="title is-size-5 mb-1">
                                        Nom Prénom
                                    </h5>
                                    <p class="font-wb">
                                        {{userDatails.nom}} {{userDatails.prenom}}
                                    </p><br/>
                                    <h5 class="title is-size-5 mb-1">
                                        Date de naissance
                                    </h5>
                                    <p>
                                        {{userDatails.dtNaissance}}
                                    </p>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <h5 class="title is-size-5 mb-1">Mon e-mail</h5>
                                    <p>{{userDatails.mail}}</p>
                                </div>
                                <div class="column">
                                    <h5 class="title is-size-5 mb-1">Téléphone</h5>
                                    <p>{{userDatails.mobile}}</p>
                                </div>
                            </div>
                            <div class="columns mes-colums">
                                <div class="column">
                                    <h5 class="title is-size-5 mb-1">Rue</h5>
                                    <p>{{userDatails.adresse1}}</p>
                                </div>
                                <div class="column">
                                    <h5 class="title is-size-5 mb-1">Numéro</h5>
                                    <p>{{userDatails.adresse2}}</p>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <h5 class="title is-size-5 mb-1">Ville</h5>
                                    <p>{{userDatails.ville}}</p>
                                </div>
                                <div class="column">
                                    <h5 class="title is-size-5 mb-1">Code Postal</h5>
                                    <p>5300</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="notification has-background-white password_section tab-pass">
                        <h4 class="title is-size-4">
                            Modification du mot de passe
                        </h4>
                        
                        <div class="togggleModifyPassword" *ngIf="isModifyPasswordFormShow=='true'">
                            <div class="columns">
                                <div class="column">
                                    <p>Mot de passe actuel</p>
                                    <input type="password">
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <p>Nouveau mot de passe</p>
                                    <input type="password">
                                </div>
                                <div class="column">
                                    <p>Confirmer le mot de passe</p>
                                    <input type="password">
                                </div>
                            </div>
                            <button class="button is-link is-small">
                                <span>Valider</span>
                                
                            </button>
                        </div>
                        <button class="button is-secondary" style="width: auto;" *ngIf="isModifyPasswordFormShow=='false'" (click)="showPasswordForm()">
                            <span class="icon">
                            <i class="fas fa-key"></i>
                        </span>
                            <span>
                            Modifier mon mot de passe
                        </span></button>


                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>