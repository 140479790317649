<app-header></app-header>

<!-- /.section -->
<section class="section pt-2 dashboard_section">
    <div class="container">
        
        <app-top-menu></app-top-menu>

        <h2 class="title is-2">Bonjour, {{loggedInName}}</h2>
        <h4 class="subtitle is-4">Veuillez choisir le pack dont vous souhaitez voir les assurances</h4>
        
        <div *ngIf="isLoading" class="apiLoader">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        
        <div *ngIf="isLoading==false && packsLists.length > 0">
            <div class="columns">
                <div class="column is-half" *ngFor="let singlePack of packsLists; let i = index" >
                    <div class="notification tab-dsk-noti">
                        <div class="columns">
                            <div class="column is-two-thirds">
                                <h2 class="title is-size-4">Pack n°{{updatePackName(singlePack.PACK)}}<br/>
                                    <span class="subTitle">{{singlePack.NOM}}</span>
                                    <p class="packMail" *ngIf="userMail!=''"><b>{{userMail}}</b></p>
                                </h2>
                            </div>
                            <div class="column posti-coulm">
                                <div class="has-text-right">
                                    <span *ngIf="mntAmount<1" class="tag is-primary is-medium">En ordre</span>
                                    <span *ngIf="mntAmount>0" class="tag is-danger is-medium">{{mntAmount}} € à payer</span>
                                </div>
                            </div>
                        </div>
                        <ul class="packExtraDetails">
                            <li *ngIf="singlePack.ADRHAB!=null">
                                <span class="icon">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span>{{singlePack.ADRHAB}}</span>
                            </li>
                            <li *ngIf="singlePack.MARQUEVEH!=null || singlePack.IMMATVEH!=null">
                                <span class="icon">
                                <i *ngIf="singlePack.CATEGORIEVEH=='Automobile'" class="fas fa-car"></i>
                                <i *ngIf="singlePack.CATEGORIEVEH!='Automobile'" class="fas fa-motorcycle"></i>
                                </span>
                                <span>{{singlePack.MARQUEVEH}} {{singlePack.IMMATVEH}}</span></li>
                            <!-- <li><span class="icon">
                                <i class="fas fa-motorcycle"></i>
                                </span>
                                <span>{{singlePack.TYPEVEH}}</span></li> -->
                        </ul>
                        <div class="has-text-centered">
                            <a class="button is-link" (click)="go_single_pack(i)">Voir le pack</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</section>
<!-- /.section -->

<app-footer></app-footer>