import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title = "Login";
  isLoading = false;
  isShowLogoutMessage = "";
  showPassword = false
  
  constructor(private http: HttpClient,public router: Router, private titleService: Title) {
    this.titleService.setTitle(environment.APP_TITLE + ' | '+ this.title);
    window.location.href = "https://five.be/vos-packs-Five-Insurance";
  }

  ngOnInit(): void {
    if(localStorage.getItem('loggedInToken')!=undefined && localStorage.getItem('loggedInToken')!=null){
      this.router.navigate(['dashboard']);
    }
    if(sessionStorage.getItem('isLoggedOut')!=undefined && sessionStorage.getItem('isLoggedOut')!=null){
      sessionStorage.removeItem('isLoggedOut');
      this.isShowLogoutMessage = "Déconnexion réussie!";
    }
  }
  
  isError = "";
  loginForm = new FormGroup({
    userPassword: new FormControl('', [Validators.required]),
    userEmail: new FormControl('', [Validators.required, Validators.email])
  });

  get f(){
    return this.loginForm.controls;
  }

  isPasswordShow(){
    if(this.showPassword){
      this.showPassword = false;
    }else{
      this.showPassword = true;
    }
  }

  /* Validate & Login Via username password */
  doLogin(){
    this.isError = "";
    this.isLoading = true;
    if(this.loginForm.value.userPassword=="" || this.loginForm.value.userEmail==""){
      this.isError = "E-mail et mot de passe doivent être requis.";
    }

    if(this.isError==""){
      const httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }
      const requestBody=JSON.stringify({
        "uid": this.loginForm.value.userEmail,
        "passwd": this.loginForm.value.userPassword 
      }); 
      
      this.http.post<any>(environment.API_BASE_URL+'/login',requestBody,httpOptions).subscribe(
        (response) => {
          var responseJson = response;
          if(responseJson.statusCode==0 && responseJson.token!=""){
            localStorage.setItem('loggedInToken',responseJson.token);
            this.router.navigate(['dashboard']);
          }else{
            this.isError = responseJson.statusMessage;
          }
          this.isLoading = false;
        },
        (error) => { console.log(error);
          this.isError = "Le nom d'utilisateur et le mot de passe ne correspondent pas.";
          this.isLoading = false;
        }
      )
    }
  }

}
