<section class="hero is-primary is-fullheight">
    <div class="hero-body">
        <div class="container">
            <div class="columns is-centered">
                <div class="column is-5-tablet is-4-desktop is-4-widescreen">
                    <form [formGroup]="loginForm" (ngSubmit)="doLogin()" class="box">
                        <div class="field">
                            <label for="" class="label">E-mail</label>
                            <div class="control has-icons-left">
                                <input type="email" formControlName="userEmail" placeholder="L'adresse de votre compte client" class="input" required>
                                <span class="icon is-small is-left">
                                    <i class="fa fa-envelope"></i>
                                </span>
                                
                            </div>
                        </div>
                        <div class="field">
                            <label for="" class="label">Mot de passe</label>
                            <div class="control has-icons-left">
                                <span *ngIf="showPassword==false" class="btnShowPassword" (click)="isPasswordShow()"><i class="fa fa-eye"></i></span>
                                <span *ngIf="showPassword" (click)="isPasswordShow()" class="btnShowPassword"><i class="fa fa-eye-slash"></i></span>

                                <input *ngIf="showPassword==false" type="password" formControlName="userPassword" placeholder="***********" class="input" required>
                                <input *ngIf="showPassword" type="text" formControlName="userPassword" placeholder="***********" class="input" required>
                                <span class="icon is-small is-left"><i class="fa fa-lock"></i></span>
                            </div>
                        </div>
                        <div class="field">
                            <label for="remember_me" class="checkbox">
                  <input type="checkbox" id="remember_me">
                 Se souvenir de moi
                </label>
                        </div>
                        <div class="field">
                            <div class="alert alert-danger" *ngIf="isError!=''">
                                {{isError}}
                            </div>
                            <div class="alert alert-success" *ngIf="isShowLogoutMessage!=''">
                                {{isShowLogoutMessage}}
                            </div>
                            
                        </div>
                        <div class="field">
                            <button type="submit" class="button is-info">
                            Se connecter 
                            </button>
                            <i style="float: right;margin-top: 10px; font-size: 20px;" class="fa fa-refresh fa-spin" *ngIf="isLoading"></i>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>