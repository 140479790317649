import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {

  constructor(public router: Router) { }

  isShow = localStorage.getItem('isShowInnerMenu');  
  quickAccessLink = "/quick-access/"+localStorage.getItem('singlePackIndex');
  countPack = JSON.parse(localStorage.getItem('packLists') || '[]').length;
  mobileMenuToggle = 2;
  ngOnInit(): void {
  }

  logout(){
    localStorage.removeItem('brokerDetails');
    localStorage.removeItem('connectedUserRelated');
    localStorage.removeItem('isShowInnerMenu');
    localStorage.removeItem('singlePackIndex');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('packLists');
    localStorage.removeItem('loggedInToken');
    sessionStorage.setItem('isLoggedOut','yes');
    this.router.navigate(['']);
  }

  updateMenuBarToggle(newStatus:any){
    this.mobileMenuToggle = newStatus;
  }

}
