import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  title = 'Contact';
  message = "";
  service = "";
  msgError = "";
  isLoading = false;
  isSuccess = "";

  constructor(private titleService: Title, private http: HttpClient) { 
    this.titleService.setTitle(environment.APP_TITLE + ' | '+ this.title);
  }

  ngOnInit(): void {
  }

  sendMailToBroker(){
    this.msgError = "";
    if(this.message=="" || this.service==""){
      this.msgError = "Le message doit être obligatoire !";
    }else{
      var brokerDetailsFromStorage = JSON.parse(localStorage.getItem('brokerDetails') || '{}');
      var mailConfig = {
        "requests": [
            {
                "params": {
                    "confid": "0",
                    "destinataires": [
                        {
                            "mail": brokerDetailsFromStorage.EMAIL_PER,
                            // "mail":"libramont@ofac.be",
                            "nom": this.service
                        }
                    ],
                    "entite": "11056624",
                    "mailConfig": {
                        "id": "317",
                        "mvt":"mvt",
                        "service":"PERSONNE"
                    },
                    "service": "PERSONNE",
                    "type": "fusion"
                }
            }
        ]
      };
      
      //console.log(mailConfig);
      const httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
      }
      var requestBody=JSON.stringify(mailConfig); 
      this.isLoading= true;
      this.http.post<any>(environment.API_BASE_URL+'/mail',requestBody,httpOptions).subscribe(
        (response) => {
          var responseJson = response;
          if(responseJson.statusCode==0){
            this.isSuccess = "Courrier envoyé avec succès.";
          }else{
            this.msgError = responseJson.statusMessage;
          }
          this.isLoading = false;
        },
        (error) => { console.log(error);
          this.msgError = "Erreur lors du contact avec le courtier.";
          this.isLoading = false;
        }
      )
    }

    
  }
}
