<app-header></app-header>
<section class="section pt-2 assurances_section table_sm_ui">
    <div class="container">
        <app-top-menu></app-top-menu>
        <div class="container">
            <h3 class="title is-size-3">Mes assurances</h3>
            <h4 class="subtitle is-size-4">{{  updatePackName(currentPack.PACK) }} {{  currentPack.NOM}}</h4>
        </div>
    </div>
    <div class="container">
        <table class="table center is-fullwidth is-striped">
            <thead>
                <tr>
                    <th>Formule</th>
                    <th>Risque</th>
                    <th>Date de début</th>
                </tr>
            </thead>
            <tbody>
                <ng-container  *ngFor="let singlePack of packsLists; let i = index">
                        <ng-container><!-- *ngIf="currentPack.PACK==singlePack.PACK" -->
                            <tr *ngIf="singlePack.CDPOS=='EC' || singlePack.CDPOS=='SUS'">
                                <td><span class="has-text-success">•</span>{{singlePack.LIBPRODUIT}}</td>
                                <td *ngIf="singlePack.IMMATVEH==null && singlePack.ADRHAB!=null ">{{singlePack.ADRHAB}}</td>
                                <td *ngIf="singlePack.IMMATVEH!=null">{{singlePack.IMMATVEH}}</td>
                                <td *ngIf="singlePack.IMMATVEH==null && singlePack.ADRHAB==null"></td>
                                <td>{{singlePack.DTEFFET}}</td>
                            </tr>
                        </ng-container>
                </ng-container>
            </tbody>
        </table>
        <div class="has-text-centered"> 
            <!-- <a [routerLink]="['/contact']" class="button is-link">Voir le contrat de mes assurances</a> -->
            <button class="button is-link" *ngIf="getDownloadMesAssurancesId()!='0'" (click)="getAdDoc(getDownloadMesAssurancesId())">Voir le contrat de mes assurances 
                <span *ngIf="currentDoc==getDownloadMesAssurancesId() && docLoader"><i class="fa fa-refresh fa-spin"></i></span>
            </button>
        </div>
    </div>
</section>
<app-footer></app-footer>