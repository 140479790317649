import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionService {

  constructor(public router: Router) {}

  userLogout(){
    localStorage.removeItem('brokerDetails');
    localStorage.removeItem('connectedUserRelated');
    localStorage.removeItem('isShowInnerMenu');
    localStorage.removeItem('singlePackIndex');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('packLists');
    localStorage.removeItem('loggedInToken');
    sessionStorage.setItem('isLoggedOut','yes');
    
    this.router.navigate(['']);
  }

  isUserLoggedIn(){
    if(localStorage.getItem('loggedInToken')==undefined || localStorage.getItem('loggedInToken')==null){
      this.router.navigate(['']);
    }
  }

  getCurrentPackDetails(keyName : any){
    if(keyName=="PACK"){
      return localStorage.getItem('singlePackIndex');
    }
    // var currentPack = Number(localStorage.getItem('singlePackIndex'));
    // var getAllPacks = localStorage.getItem('packLists');
    // getAllPacks = JSON.parse(getAllPacks || '{}');

    // if(getAllPacks!=null && getAllPacks.length > 0){
    //   var currentPackJson = getAllPacks[currentPack];
    //   return currentPackJson[keyName];
    // }
    return '';
  }

  getUserProfileDetails(keyName : any){
    var userProfile = localStorage.getItem('userProfile');
    userProfile = JSON.parse(userProfile || '{}');
    if(userProfile!=null){
      if(keyName=='adresses'){
        var adresse1:any = 'adresse1';
        return userProfile[keyName][0][adresse1];  
      }
      if(keyName=='adresse2'){
        var adresses:any = 'adresses';
        return userProfile[adresses][0][keyName];  
      }
      if(keyName=='ville'){
        var adresses:any = 'adresses';
        return userProfile[adresses][0][keyName];  
      }
      return userProfile[keyName];
    }
    return 'false';
  }

}
