import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { AssurancesComponent } from './assurances/assurances.component';
import { SinistresComponent } from './sinistres/sinistres.component';
import { DocumentsComponent } from './documents/documents.component';
import { QuickAccessComponent } from './quick-access/quick-access.component';
import { ContactComponent } from './contact/contact.component';
import { NewSinistreComponent } from './new-sinistre/new-sinistre.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  // { path: 'dashboard', component: DashboardComponent },
  { path: 'assurances', component: AssurancesComponent },
  { path: 'sinistres', component: SinistresComponent },
  { path: 'documents', component: DocumentsComponent },
  { path: 'documents/:filterId', component: DocumentsComponent },
  { path: 'quick-access/:packId', component: QuickAccessComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'new-sinistres', component: NewSinistreComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
