<app-header></app-header> 
<!-- .hero -->
<section class="section pt-2">
    <div class="container">

        <app-top-menu></app-top-menu>
        
        <div class="container">
            <h2 class="title is-size-2 mb-0">Pack n°2329</h2>
            <h3 class="title is-size-3">Barbiaux Maxime</h3>
            <div class="columns is-centered">
                <div class="column is-8-desktop ">
                    <div class="notification has-background-light contact-detils">
                        <div class="columns ">
                            <div class="column ">
                                <form (submit)="sendMailToBroker()">
                                    <div class="field ">
                                        <label class="label ">Sujet</label>
                                        <div class="control ">
                                            <div class="select ">
                                                <select [(ngModel)]="service" name="service">
                                            <option selected>Modification de mes informations</option>
                                            <option>Assurances</option>
                                            <option>Changement d'adresse</option>
                                            <option>Autres</option>

                                            </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field ">
                                        <label class="label ">Message</label>
                                        <div class="control ">
                                            <textarea class="textarea " [(ngModel)]="message" name="message" placeholder="Votre message "></textarea>
                                            <div class="isError" *ngIf="msgError!=''">{{msgError}}</div>
                                            <div style="    margin-top: 20px;" class="alert alert-success" *ngIf="isSuccess!=''">{{isSuccess}}</div>
                                        </div>
                                    </div>

                                    <div class="field is-grouped ">
                                        <div class="control ">
                                            <button class="button is-link " *ngIf="isLoading==false">Envoyer</button>
                                            <button class="button is-link " *ngIf="isLoading"><i class="fa fa-refresh fa-spin"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>