import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { CommonFunctionService } from '../common-function.service';  

@Component({
  selector: 'app-assurances',
  templateUrl: './assurances.component.html',
  styleUrls: ['./assurances.component.css']
})

export class AssurancesComponent implements OnInit {

  title = 'Mes assurances';
  packsLists = JSON.parse(localStorage.getItem('packLists') || '[{}]');
  currentDoc = '0';
  docLoader = false;
  currentPack = {
    PACK:'',
    NOM:'',
    index:0
  };
  
  constructor(private titleService: Title,    private http: HttpClient, private CommonFunctionService: CommonFunctionService) { 
    this.titleService.setTitle(environment.APP_TITLE + ' | '+ this.title);
  }

  ngOnInit(): void {
    this.CommonFunctionService.isUserLoggedIn();

    /* Assign current pack from construct variable */
    this.currentPack.PACK = this.CommonFunctionService.getCurrentPackDetails('PACK') || '';
    this.currentPack.NOM = this.CommonFunctionService.getCurrentPackDetails('NOM') || '';
    this.currentPack.index = Number(localStorage.getItem('singlePackIndex'));

  }

  /* Get Document from doc id */
  getAdDoc(adDocId:any){
    this.currentDoc = adDocId;
    this.docLoader = true;

    var httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization':'Bearer '+localStorage.getItem('loggedInToken')})
    }
    this.http.get<any>(environment.API_BASE_URL+'/document/v2/'+this.currentDoc,httpOptions).subscribe(
      (response) => {
        this.docLoader = false;
        this.downloadPDF(response.name,response.datas);
        
      }
    )
  }

  /* Donwload PDF */
  downloadPDF(name:any,data:any) {

    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  getDownloadMesAssurancesId(){
    var spCondsOfConUsr = JSON.parse(localStorage.getItem('specialConditionsOfConnectedUser') || '[{}]');
    var returnId = '0';
    if(spCondsOfConUsr.length > 0){
      spCondsOfConUsr.sort((a:any, b:any) => {
        return <any>new Date(b.DAT_ADOC) - <any>new Date(a.DAT_ADOC);
      });

      spCondsOfConUsr.forEach((singleSpCond : any)=> {
        if(singleSpCond.NOM_ADOC.startsWith('AVT_') && returnId=='0'){
          returnId = singleSpCond.ID_ADOC;
        }
      });
      // console.log(returnId);
    }
    return returnId;
  }

  updatePackName(packNamme:any){
    if(packNamme!=null){
      return packNamme.replace(/^0+/, '');
    }else{
      return packNamme;
    }
  }

}
