<section class="section pt-2 footer_section">
    <div class="container">
        <div class="columns">
            <div class="column">
                <div><div>
                    <ul id="menu-menu-secondaire" class="menu">
                        <li><a [routerLink]="['/contact']">Contactez-nous</a></li>
                        <li class="facebook"><a href="https://www.facebook.com/pages/Five-Insurance-Company/1422688748029111" translate="">Rejoignez la communauté Five sur Facebook</a></li>
                        <li class="linkdin"><a href="https://www.linkedin.com/company/9328519?trk=tyah&amp;trkInfo=clickedVertical%3Acompany%2Cidx%3A2-1-2%2CtarId%3A1430748492894%2Ctas%3Afive%20insurance" translate="">Retrouvez-nous sur LinkedIn</a></li>
                    </ul>
                </div></div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="section-detils">
                    <div itemprop="adr" class="addr" itemscope="">
                        <div><a  routerLinkActive="is-primary" [routerLink]="['/quick-access']"><img src="assets/img/icon-five-insurance-square.svg"></a></div>
                        <p>
                            <span itemprop="street-address">486, Chaussée de Marche</span>
                            <span itemprop="postal-code">B-5101</span>
                            <span itemprop="locality">Erpent</span>
                        </p>
                        <p>T. <span itemprop="tel">+32 81 84 15 20</span></p>
                        <p>F. +32 81 84 15 29</p>
                        <p>E. <span itemprop="email">info@five-insurance.be</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="image-footer">
            <img src="/assets/img/five-insurance-company-footer.svg">
        </div>
    </div>
    
</section>