<div class="container mb-4 mt-0">
    <!-- Navbar -->
    <!-- https://bulma.io/documentation/components/navbar/ -->

    <div class="toggleMobileNavbar" *ngIf="mobileMenuToggle==2" (click)="updateMenuBarToggle(1)">
        <i class="fa fa-bars"></i>
    </div>
    <div class="toggleMobileNavbar" *ngIf="mobileMenuToggle==1" (click)="updateMenuBarToggle(2)">
        <i class="fa fa-close"></i>
    </div>

    <nav class="navbar main-navigation-for-app" role="navigation" aria-label="main navigation" [ngClass]="{'isOpened': mobileMenuToggle==1}">
        <!-- Pour y mettre un logo -->
        <!-- <div class="navbar-brand"></div> -->

        <div class="mobileLogo">
            <div><a  routerLinkActive="is-primary" [routerLink]="['/quick-access']"><img src="assets/img/icon-five-insurance-square.svg"></a></div>
            <div><b>Compte client</b></div>
        </div>

        <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-start">
                <!-- <div class="navbar-start" *ngIf="isShow == 'true'"> -->
                <div class="buttons">
                    <a class="button" routerLinkActive="is-primary" routerLink={{quickAccessLink}}>
                        Accès rapide
                    </a>
                    <a class="button" routerLinkActive="is-primary" [routerLink]="['/assurances']">
                        Mes assurances
                    </a>
                    <!-- <a class="button" routerLinkActive="is-primary" [routerLink]="['/sinistres']">
                        Mes sinistres
                    </a> -->
                    <a class="button" routerLinkActive="is-primary" [routerLink]="['/documents']">
                        Mes documents
                    </a>
                </div>
            </div>
        </div>
        <div class="navbar-end">
            <a class="navbar-item" href="https://five.be/vos-packs-five-insurance">
                    Changer de pack
                </a>
            <a class="navbar-item has-text-danger" href="https://five.be/se-deconnecter">
            Se déconnecter
            </a>
        </div>
    </nav>
</div>