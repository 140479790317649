<app-header></app-header>

<section class="section pt-2 new-sinistres_section">
    <div class="container">

        <app-top-menu></app-top-menu>


        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><a [routerLink]="['/']">Accueil</a></li>
                <li><a [routerLink]="['/contact']">Contacter mon courtier</a></li>
            </ul>
        </nav>
        <div class="container">
            <h2 class="title is-size-2 mb-0">Pack n°2329</h2>
            <h3 class="title is-size-3">Barbiaux Maxime</h3>
            <div class="columns is-centered">
                <div class="column is-8-desktop ">
                    <div class="notification has-background-light ">
                        <div class="columns ">
                            <div class="column ">
                                <h3 class="title is-3 ">Déclarer un sinistre</h3>
                                <div class="field ">
                                    <label class="label ">Sujet</label>
                                    <div class="control ">
                                        <div class="select ">
                                            <select>
                                                <option>Accident de voiture</option>
                                                <option>Vol</option>
                                                <option>Inondation ou dégât des eaux</option>
                                                <option>Tempête</option>
                                                <option>Incendie</option>
                                                <option>Autres</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="field ">
                                    <label class="label ">Date du sinistre</label>
                                    <div class="control ">
                                        <input type="text " class="input " placeholder="__/__/____ "/>
                                    </div>
                                </div>

                                <div class="field ">
                                    <label class="label ">Description du sinistre</label>
                                    <div class="control ">
                                        <textarea class="textarea " placeholder="Votre message "></textarea>
                                    </div>
                                </div>

                                <h5>Veuillez joindre une ou plusieurs photo(s) du sinistre</h5>
                                <div class="file pb-3 ">
                                    <br/>
                                    <label class="file-label ">
                                        <input class="file-input " type="file " name="resume ">
                                        <span class="file-cta ">
                                        <span class="file-icon ">
                                            <i class="fas fa-upload "></i>
                                        </span>
                                        <span class="file-label ">
                                            Choisir un fichier…
                                        </span>
                                        </span>
                                    </label>
                                </div>

                                <div class="field ">
                                    <div class="control ">
                                        <button class="button is-link ">Envoyer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>